<template>
  <div class="order">
    <div class="content">
      <div class="tit">
        <el-select class="box one" clearable v-model="procuratorate" placeholder="请选择检察院">
          <el-option
            v-for="item in prolist"
            :key="item.id"
            :label="item.procuratoratename"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select class="box two" clearable v-model="cardname" placeholder="请选择案件类型">
          <el-option
            v-for="item in cardlist"
            :key="item.id"
            :label="item.cardname"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select class="box two" clearable v-model="visitstatusname" placeholder="请选择审核状态">
          <el-option
            v-for="item in visitstatuslist"
            :key="item.id"
            :label="item.visitstatusname"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select class="box two" clearable v-model="vusername" placeholder="请选择审核人员">
          <el-option
            v-for="item in vuserlist"
            :key="item.id"
            :label="item.username"
            :value="item.id"
            >
          </el-option>
        </el-select>
        <el-input class="box four" placeholder="内容搜索" v-model="phone" clearable> </el-input>
        <el-date-picker
          v-model="value2"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
        <el-button
          slot="reference"
          @click="search"
          type="primary"
          class="style_button style_button_Sel"
          icon="el-icon-search"
          >搜索</el-button>
        <!-- <el-button class="box submit" type="primary" icon="el-icon-search" @click="search">搜索</el-button> -->
      </div>
      <div class="table">
        <el-table
          :data="tableData"
          style="width: 100% "
          :header-cell-style="{textAlign:'center'}"
          :cell-style="{textAlign:'center'}"
          @click="row => rowClick('primary', row)">
          <el-table-column
            type="index"
            label="序号"
            width="60">
          </el-table-column>
          <el-table-column
            prop="visitcontext"
            label="信访内容"
            width="250"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="username"
            label="信访人"
            width="100"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="cardname"
            label="案件类型"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="procuratoratename"
            label="检察院"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="vusername"
            label="审核人员"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="visitstatusname"
            label="审核状态"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="time"
            label="预约日期"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="visitstarttime"
            label="开始时间"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row)" type="text" size="small">查看详情</el-button>
              <el-button type="text" size="small" v-if="scope.row.visitstatusid == 7" @click="dialogtext(scope.row)">接访纪要</el-button>
              <el-button type="text" size="small" v-else @click="gohome(scope.row)">进入房间</el-button>
              <!-- <el-button type="text" size="small" @click="gohome(scope.row)">{{handleTime(scope.row)}}</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="page"
            :pager-count="9"
            layout="total, prev, pager, next, jumper"
            :total="total"
            hide-on-single-page>
          </el-pagination>
        </div>
      </div>
      <el-dialog
        title="查看详情"
        :visible.sync="dialogVisible"
        width="1172px"
        :before-close="handleClose">
        <el-descriptions :column="2">
            <el-descriptions-item label="信访人姓名">{{dialoglist.username}}</el-descriptions-item>
            <el-descriptions-item label="接访单位">{{dialoglist.pname}}</el-descriptions-item>
            <el-descriptions-item label="性别">{{dialoglist.sex}}</el-descriptions-item>
            <el-descriptions-item label="审核人员">{{dialoglist.checkmanphone}}</el-descriptions-item>
            <el-descriptions-item label="单位名称">{{dialoglist.depname}}</el-descriptions-item>
            <el-descriptions-item label="案件类型">{{dialoglist.casename}}</el-descriptions-item>
            <el-descriptions-item label="手机号码">{{dialoglist.phone}}</el-descriptions-item>
            <el-descriptions-item label="预约日期">{{dialoglist.applytime}}</el-descriptions-item>
            <el-descriptions-item label="证件类型">{{dialoglist.cardname}}</el-descriptions-item>
            <el-descriptions-item label="审核结果">{{result}}</el-descriptions-item>
            <el-descriptions-item label="证件号码">{{dialoglist.cardnum}}</el-descriptions-item>
            <el-descriptions-item label="接访日期">{{dialoglist.date}}</el-descriptions-item>
            <el-descriptions-item label="居住地">{{dialoglist.address}}</el-descriptions-item>
            <el-descriptions-item label="接访时间">{{dialoglist.time}}</el-descriptions-item>
            <el-descriptions-item label="信访内容">{{dialoglist.text}}</el-descriptions-item>
            <el-descriptions-item label="备注">{{dialoglist.note}}</el-descriptions-item>
        </el-descriptions>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="接访纪要"
        :visible.sync="dialog"
        width="672px">
        <el-descriptions :column="1">
            <el-descriptions-item label="信访人">{{username}}</el-descriptions-item>
            <el-descriptions-item label="接访人">{{checkname}}</el-descriptions-item>
            <el-descriptions-item label="记录人">{{name}}</el-descriptions-item>
            <el-descriptions-item label="信访内容">{{textarea2}}</el-descriptions-item>
        </el-descriptions>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialog = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import{getVisitsList,getOneVisits,getRoomVisit,getOneVisitRecording} from '@/utils/api'
import { Message } from 'element-ui';
export default {
  data(){
    return{
        vlaue1:'',
        options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
        prolist:[],
        procuratorate:'',
        proid:'',
        cardlist:[],
        cardname:'',
        cardid:'',
        visitstatuslist:[],
        visitstatusname:'',
        visitstatusid:'',
        vuserlist:[],
        vusername:'',
        vuserid:'',
        phone:'',
        value2:'',
        tableData: [],
        currentPage4: 1,
        total:0,
        page:10,
        dialogVisible:false,
        dialog:false,
        dialoglist:{},
        result:'',
        time :'',
        username:"",
        checkname:'',
        name:'',
        textarea2:''
    }
  },
  methods: {
    handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
    },
    // 分页
    handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.currentPage4 = val
        let endTime = '';
        let startTime = '';
        // console.log(this.value2,'data')
        if (this.value2 !== '' && this.value2 !== null) {
          // console.log(this.value2,'进来了')
          endTime = this.value2[1] ;
          startTime = this.value2[0];
          endTime=endTime.getFullYear() + '-' + (endTime.getMonth() + 1) + '-' + endTime.getDate();
          startTime=startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate();
        }else{
          endTime = '';
          startTime = '';
        }
        // console.log(this.currentPage4);
        this.getvisitlist(startTime,endTime)
    },
    // 详情
    handleClick(row) {
        // console.log(row);
        this.result = row.visitstatusname
        this.dialogVisible=true
        getOneVisits({
          visitid:row.id
        }).then(res =>{
          // console.log('回显',res);
          this.dialoglist=res.data.data
        })
    },
    // 跳转视频通话
    gohome(row){
      getRoomVisit({
        visitId:row.id
      }).then(res =>{
        // console.log('数据',res);
        if(res.data.code == 200){
          this.$router.push(`/home?rooid=${row.room}&id=${row.id}`)
        }
      })
      // let endDate = new Date(row.visitstarttime);
      // let end = endDate.getTime();
      // var date = new Date();
      // var now = date.getTime();
      // var leftTime = end - now;
      // if (leftTime >= 0) {
      //   var d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
      //   var h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
      //   var m = Math.floor(leftTime / 1000 / 60 % 60);
      //   var s = Math.floor(leftTime / 1000 % 60);
      // }
      // if(row.room !== null &&  row.room !== '' &&  row.room !== undefined){
        // console.log(d,h,m);
      //   if(d == 0 &&h == 0 && m <= 5){
      //     getRoomVisit({
      //       visitId:row.id
      //     }).then(res =>{
            // console.log(res);
      //       if(res.data.code == 200){
      //         this.$router.push(`/home?rooid=${row.room}`)
      //       }
      //     })
      //   }else{
      //     Message({ message:`接访时间五分钟内可以进入接访室！`,type:"warning" })
      //   }
      // }else{
      //   Message({ message:`${row.visitstatusname}不能进入接访室！`,type:"warning" })
      // }
    },
    // 关闭模态框
    handleClose(done) {
      this.dialogVisible = false
        // this.$confirm('确认关闭？')
        //   .then(_ => {
        //     done();
        //   })
        //   .catch(_ => {});
    },
    // 获取接访列表
    getvisitlist(startTime,endTime){
      let data = {
        pid:this.procuratorate,   //检察院id
        did:this.cardname,   //案件类型id
        vid:this.visitstatusname,   //审核状态id
        userId:this.vusername,   //用户
        phone:this.phone,   //手机号
        startTime:startTime,   //开始时间
        endTime:endTime,   //结束时间
        limit:this.currentPage4,  //第几页
        page:this.page,  //每页数量
      }
      getVisitsList(data).then(res =>{
        // console.log('接访列表',res);
        res.data.data.data.map(item =>{
          let time = item.visitstarttime
          if(item.visitstarttime !== undefined){
            var date = time.replace("T"," ")
          }
          item.visitstarttime = date
          return item
        })
        
        this.tableData = res.data.data.data
        this.vuserlist = res.data.data.user
        this.prolist = res.data.data.userId
        this.cardlist = res.data.data.dict
        this.visitstatuslist = res.data.data.static
        if(res.data.data.total.length !== 0){
          this.total = res.data.data.total[0].total
        }
      })
    },
    // 搜索
    search(){
      // console.log(this.procuratorate,this.cardname);
      // if(this.value2 !== ''){
      //   let time = this.value2.map(item =>{
      //     // let b = item.toLocaleTimeString();//输出时 分 秒
      //     let c = item.toLocaleDateString();//输出年 月 日
      //     let d = c.split("/");
      //     if (d[1] < 10) {
      //         d[1] = '0' + d[1]
      //     }
      //     item = d.join('-')
      //     return item
      //   })
        
      //   this.value2 = time
      // }
      var endTime = '';
      var startTime = '';
      // console.log(this.value2,'data')
      if (this.value2 !== '' && this.value2 !== null) {
        // console.log(this.value2,'进来了')
        endTime = this.value2[1] ;
        startTime = this.value2[0];
        endTime=endTime.getFullYear() + '-' + (endTime.getMonth() + 1) + '-' + endTime.getDate();
        startTime=startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate();
      }else{
       endTime = '';
       startTime = '';
      }
      // console.log(endTime,startTime);
      this.getvisitlist(startTime,endTime)
    },
    handleTime(row){
      // console.log('时间差',row.visitstarttime);
      let time = row.visitstarttime
      this.time = row.visitstarttime
      // this.gettime(time)
      this.countTime()
    },
    //   倒计时
    countTime() {
      // console.log('时间差',this.endtime);
      //获取当前时间
      let time = this.time
      if(time !== undefined){

        var date = new Date();
        var now = date.getTime();
        //设置截止时间
        var endDate = new Date(time);
        var end = endDate.getTime();
        //时间差
        var leftTime = end - now;
        //定义变量 d,h,m,s保存倒计时的时间
        if (leftTime >= 0) {
          var d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
          var h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
          var m = Math.floor(leftTime / 1000 / 60 % 60);
          var s = Math.floor(leftTime / 1000 % 60);
          if(d==0&&h==0&&m<5){
            var text = '进入房间'
          }else{
            var text = '等待接访'
          }
        }
        // console.log(s);
          //递归每秒调用countTime方法，显示动态时间效果
        // console.log(d + ":" + h + ":" + m + ":" + s);
        // console.log(text);
        return text;
      }
    },
    // 接访纪要
    dialogtext(row){
      // console.log(row);
      getOneVisitRecording({
        visitId:row.id
      }).then(res =>{
        // console.log(res);
        if(res.data.data !== null && res.data.data !== undefined && res.data.data !== ''){
          this.dialog = true
          this.username = row.username
          this.checkname = row.vusername
          this.name = res.data.data.name
          this.textarea2 = res.data.data.text
        }
        
      })
    }
  },
  mounted() {
    var endTime = '';
    var startTime = '';
    this.getvisitlist(startTime,endTime)
    
  },
}
</script>

<style  lang="less" scoped>
.order{
  min-height: 95%;
  background: #fff;
  box-shadow: 0px 3px 20px 0px rgba(131,131,131,0.1800);
  border-radius: 4px;
  padding: 24px;
}
.content{
  .tit{
    display: flex;
    .box{
      margin-right: 24px;
      height: 36px;
    }
    .one{
      width: 140px;
    }
    .two{
      width: 189px;
    }
    .four{
      width: 130px;
    }
    .five{
      width: 287px;
    }
    .submit{
      width: 94px;
    }
  }
  .table{
    margin-top: 25px;
    .block{
      margin-top: 27px;
    }
  }
}
.el-pagination {
    text-align: center; 
}
.el-table th.el-table__cell>.cell{
  text-align: center !important;
}
.el-table--enable-row-transition .el-table__body td.el-table__cell{
  text-align: center !important;
}
::v-deep .el-dialog__header{
  border-bottom: 1px solid #D8D8D8;
}
::v-deep .el-select{
  width: 140px !important;
}
::v-deep .tit .el-button{
  margin-left: 15px !important;
}
</style>